<template>
  <div class="c-partners">
    <h2
      class="c-partners__title text-center text-neutral-base text-2xl font-bold my-10"
    >
      Parceiros
    </h2>
    <div class="c-partners__list lg:flex justify-between">
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 mb-2 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="">
          <img
            src="../assets/images/logo-parceiros/avina.png"
            alt="Avina Americas"
          />
        </a>
      </div>
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 mb-2 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="" class="flex justify-center">
          <img
            src="../assets/images/logo-parceiros/ilda.png"
            alt="La Iniciativa Latinoamericana por los Datos Abiertos - ILDA"
          />
        </a>
      </div>
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 mb-2 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="">
          <img
            src="../assets/images/logo-parceiros/open-data-development.png"
            alt="Open Data for Development"
          />
        </a>
      </div>
      <!-- <img src="../../assets/images/logo-parceiros/" alt="Open Data for Development" /> -->
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 mb-2 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="">
          <img
            src="../assets/images/logo-parceiros/ok.png"
            alt="Open Knowledge Brasil"
          />
        </a>
      </div>
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 mb-2 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="">
          <img src="../assets/images/logo-parceiros/colab.png" alt="COLAB" />
        </a>
      </div>
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="">
          <img src="../assets/images/logo-parceiros/fecap.png" alt="Fecap" />
        </a>
      </div>
    </div>
    <h2
      class="c-partners__title text-center text-neutral-base text-2xl font-bold my-10"
    >
      Apoio
    </h2>
    <div class="c-partners__list flex justify-center mb-10">
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="">
          <img
            src="../assets/images/logo-parceiros/gandi.png"
            alt="Gandi.net"
          />
        </a>
      </div>
      <div
        class="p-6 mx-3 lg:w-1/5 h-32 flex items-center justify-center border-2 rounded-md text-border-base"
      >
        <a href="">
          <img
            src="../assets/images/logo-parceiros/bs.png"
            alt="BrowserStack"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
